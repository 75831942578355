<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  methods: {
    handleLogout(event) {
      event.preventDefault();
      this.logout();
      this.$router.push("/");
    },
    ...mapActions(["logout"]),
  },
};
</script>

<style lang="scss">
// @import "~@/styles/app.scss";
</style>