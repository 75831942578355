import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import { loginUser, logoutUser } from '../services/api/auth';
import app from './modules/app';
import settings from './modules/settings';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: 'admin',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    isLoggedIn: false,
  },
  mutations: {
    loginSuccess(state, userId) {
      state.user = userId;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
  actions: {
    login({ commit }, { username, password }) {
      return loginUser(username, password)
        .then(() => {
          commit({ type: 'loginSuccess', username });
          return Promise.resolve();
        }).catch((error) => {
          commit({ type: 'logout' });
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      logoutUser();
      commit('logout');
    },
  },
  modules: {
    app,
    settings,
  },
  getters,
});
