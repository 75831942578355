/* eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
//import Home from '../views/Home.vue';
// import Login from '../views/Login.vue';
//import AuthenticatedPing from '../views/AuthenticatedPing.vue';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../services/api/auth';

Vue.use(VueRouter);

const PUBLIC_PATHS = ['/', '/login'];

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/cloudresource',
    children: [{
      path: 'index',
      name: 'cloudresource',
      component: () => import('@/views/cloudresourcelist/index'),
      meta: { title: '云资源', icon: 'dashboard' }
    }]
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/dashboard',
  //   children: [{
  //     path: 'dashboard',
  //     name: 'Dashboard',
  //     component: () => import('@/views/dashboard/index'),
  //     meta: { title: 'Dashboard', icon: 'dashboard' }
  //   }]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/cloudresource',
  //   children: [{
  //     path: 'cloudresource',
  //     name: 'cloudresource',
  //     component: () => import('@/views/cloudresourcelist/index'),
  //     meta: { title: '云资源', icon: 'dashboard' }
  //   }]
  // },
  {
    path: '/cloudresource',
    component: Layout,
    // redirect: '/cloudresource',
    hidden: true,
    children: [{
      path: 'index',
      name: 'cloudresource',
      component: () => import('@/views/cloudresourcelist/index'),
      meta: { title: '云资源', icon: 'dashboard' }
    }]
  },
  {
    path: '/cloudapplications',
    component: Layout,
    // redirect: '/cloudapplications',
    children: [{
      path: 'index',
      name: 'cloudapplications',
      component: () => import('@/views/cloudapplist/index'),
      meta: { title: '云应用', icon: 'dashboard' }
    }]
  },
  {
    path: '/cloudappdeploy',
    component: Layout,
    hidden: true,
    // redirect: '/cloudappdeploy',
    children: [{
      path: 'index',
      name: 'cloudappdeploy',
      component: () => import('@/views/cloudappdeploy/index'),
      meta: { title: '云应用部署', icon: 'dashboard' }
    }]
  },
  {
    path: '/templates',
    component: Layout,
    // redirect: '/templates',
    children: [{
      path: 'index',
      name: 'templates',
      component: () => import('@/views/templates/index'),
      meta: { title: '模板', icon: 'dashboard' }
    }]
  },
  {
    path: '/login',
    name: 'Login',
    //    component: Login,
    component: () => import('@/views/login/index'),
  },
  // {
  //   path: '/my/upload/endpoint',
  //   name: 'Uploader',
  //   //    component: Uploader,
  //   component: () => import('@/components/Uploader/index'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const unAuthenticatedAndPrivatePage = (path) => (!PUBLIC_PATHS.includes(path)
  && !(ACCESS_TOKEN in window.localStorage)
  && !(REFRESH_TOKEN in window.localStorage));

router.beforeEach((to, from, next) => {
  if (unAuthenticatedAndPrivatePage(to.path)) {
    next(`/login?next=${to.path}`);
  } else {
    next();
  }
});

export default router;
