import defaultSettings from '@/settings';

const { SHOWSettings, FIXEDHeader, SIDEBARLogo } = defaultSettings;

const settingState = {
  showSettings: SHOWSettings,
  fixedHeader: FIXEDHeader,
  sidebarLogo: SIDEBARLogo,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  },
};

export default {
  namespaced: true,
  state: settingState,
  mutations,
  actions,
};
